import { Stack, Flex, Button, Text, Image, Link } from "@chakra-ui/react";
import React from "react";

export default function Hero() {
  return (
    <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Stack spacing={6} w={"full"} maxW={"lg"} alignItems="center">
          <Image alt={"Windmill Meadows Guest Cottages"} objectFit={"cover"} src={"assets/img/wm-logo.png"} />
          <Text fontSize={{ base: "md", lg: "lg" }} color={"gray.500"} textAlign="center">
            Farmhouse inspired vacation rental cottages with modern amenities just 10 short minutes from historic downtown Fredericksburg, Texas!
          </Text>
          <Stack direction={{ base: "column", md: "row" }} spacing={4}>
            <Link href="#amenities" textDecoration={"none"} _hover={{ textDecoration: "none" }}>
              <Button
                rounded={"full"}
                bg={"white"}
                color={"#025362"}
                borderColor={"#025362"}
                border={"1px"}
                _hover={{
                  bg: "gray.100",
                }}
              >
                Amenities
              </Button>
            </Link>
            <Link href="#cottages" textDecoration={"none"} _hover={{ textDecoration: "none" }}>
              <Button
                rounded={"full"}
                bg={"#025362"}
                color={"white"}
                _hover={{
                  bg: "#013f4a",
                }}
              >
                Cottages
              </Button>
            </Link>
          </Stack>
        </Stack>
      </Flex>
      <Flex flex={1}>
        <Image alt={"Windmill Meadows Guest Cottages"} objectFit={"cover"} align="right" src={"assets/img/wm-main.jpeg"} />
      </Flex>
    </Stack>
  );
}

import { Box, Container, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { IconButton } from "./IconButton.component";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import React from "react";

export const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <Box bg={useColorModeValue("gray.50", "gray.900")} color={useColorModeValue("gray.700", "gray.200")} w="full">
      <Container
        as={Stack}
        maxW={"6xl"}
        py={4}
        direction={{ base: "column", md: "row" }}
        spacing={4}
        justify={{ base: "center", md: "space-between" }}
        align={{ base: "center", md: "center" }}
      >
        <Text>© 2021-{year} Windmill Meadows Guest Cottages. All rights reserved</Text>
        <Stack direction={"row"} spacing={6}>
          <IconButton label={"Facebook"} href={"https://www.facebook.com/WindmillMeadowsGuestCottages/"}>
            <FaFacebook />
          </IconButton>
          <IconButton label={"Instagram"} href={"https://www.instagram.com/windmillmeadows/"}>
            <FaInstagram />
          </IconButton>
        </Stack>
      </Container>
    </Box>
  );
};

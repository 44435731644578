import { chakra, useColorModeValue, VisuallyHidden } from "@chakra-ui/react";
import React from "react";
import { ReactNode } from "react";

export const IconButton = ({ children, label, href, onClick }: { children: ReactNode; label: string; href?: string; onClick?: () => void }) => {
  const bg = useColorModeValue("blackAlpha.100", "whiteAlpha.100");
  const hoverBg = useColorModeValue("blackAlpha.200", "whiteAlpha.200");
  if (href)
    return (
      <chakra.button
        bg={bg}
        rounded={"full"}
        w={8}
        h={8}
        cursor={"pointer"}
        as={"a"}
        href={href}
        display={"inline-flex"}
        alignItems={"center"}
        justifyContent={"center"}
        transition={"background 0.3s ease"}
        _hover={{
          bg: hoverBg,
        }}
        target="_blank"
      >
        <VisuallyHidden>{label}</VisuallyHidden>
        {children}
      </chakra.button>
    );
  else
    return (
      <chakra.button
        bg={bg}
        rounded={"full"}
        w={16}
        h={16}
        cursor={"pointer"}
        display={"inline-flex"}
        alignItems={"center"}
        justifyContent={"center"}
        transition={"background 0.3s ease"}
        _hover={{
          bg: hoverBg,
        }}
        onClick={onClick}
      >
        <VisuallyHidden>{label}</VisuallyHidden>
        {children}
      </chakra.button>
    );
};

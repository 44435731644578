import { chakra, Flex, Heading, Stack } from "@chakra-ui/react";
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel";
import React from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import "react-responsive-carousel/lib/styles/carousel.min.css";

interface CarouselCard {
  title?: string;
  text?: string;
  image: string;
  alt: string;
}
const cards: CarouselCard[] = [
  {
    image: "assets/img/wm-all-units-sunset.jpeg",
    alt: "Sunset over Windmill meadows",
  },
  {
    image: "assets/img/wm-drone.jpg",
    alt: "Entire Property",
  },
  {
    image: "assets/img/wm-bucks.jpg",
    alt: "Our deer guest",
  },
  {
    image: "assets/img/wm-rainbow.jpeg",
    alt: "Rainbow over our cottage",
  },{
    image: "assets/img/wm-partybarn.jpeg",
    alt: "Gazebo",
    
  },{
    image: "assets/img/wm-firepitsunset.jpg",
    alt: "Gazebo fire pit",
  },{
    image: "assets/img/wm-night-path.jpg",
    alt: "\"Party Barn\" at night",
  },{
    image: "assets/img/wm-deck-sunset.jpeg",
    alt: "Deck at Sunset",
  },{
    image: "assets/img/wm-bed-kitchen.jpg",
    alt: "Bed and Kitchenette",
  },{
    image: "assets/img/wm-kitchenette.jpg",
    alt: "Kitchenette",
  },
  {
    image: "assets/img/wm-lsbath.jpg",
    alt: "Lone Star Bathroom",
  }
];

export const Carousel = () => {
  const renderLeft = (clickHandler: () => void) => {
    return (
      <chakra.button
        bg={"rgb(1,83,98, .85)"}
        rounded={"full"}
        w={{ md: 12, base: 10 }}
        h={{ md: 12, base: 10 }}
        cursor={"pointer"}
        display={"inline-flex"}
        alignItems={"center"}
        justifyContent={"center"}
        transition={"background 0.3s ease"}
        _hover={{
          bg: "rgb(1, 63, 74, .85)",
        }}
        onClick={clickHandler}
        position={"absolute"}
        zIndex={2}
        top={"calc(50% - 15px)"}
        left={15}
      >
        <FaArrowLeft size={20} />
      </chakra.button>
    );
  };

  const renderRight = (clickHandler: () => void) => {
    return (
      <chakra.button
        bg={"rgb(1,83,98, .85)"}
        rounded={"full"}
        w={{ md: 12, base: 10 }}
        h={{ md: 12, base: 10 }}
        cursor={"pointer"}
        display={"inline-flex"}
        alignItems={"center"}
        justifyContent={"center"}
        transition={"background 0.3s ease"}
        _hover={{
          bg: "rgb(1, 63, 74, .85)",
        }}
        onClick={clickHandler}
        position={"absolute"}
        zIndex={2}
        top={"calc(50% - 15px)"}
        right={15}
      >
        <FaArrowRight size={20} />
      </chakra.button>
    );
  };

  return (
    <Flex id="carousel" backgroundColor="#025362" color={"white"} w={"full"} textAlign="center" py={"28"}>
      <Stack align="center">
        <Heading fontSize={"3xl"} letterSpacing="widest" textTransform="uppercase" pb="16">
          Photos
        </Heading>
        <Flex w={{ lg: "50%", md: "70%", sm: "92%", base: "92%" }} bg="#013f4a" p={5} m={5}>
          <chakra.div className={"carousel-wrapper"}>
            <ResponsiveCarousel
              showArrows
              infiniteLoop
              useKeyboardArrows
              renderArrowPrev={renderLeft}
              renderArrowNext={renderRight}
              dynamicHeight={false}
              showThumbs={false}
              
            >
              {cards.map((c, i) => (
                <chakra.div key={i} maxH={"760"}>
                  <chakra.img src={c.image} alt={c.alt} objectFit={"cover"} objectPosition={"center"}/>
                </chakra.div>
              ))}
            </ResponsiveCarousel>
          </chakra.div>
        </Flex>
      </Stack>
    </Flex>
  );
};

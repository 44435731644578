import * as React from "react";
import { ChakraProvider, VStack } from "@chakra-ui/react";
import { PageContainer } from "./components/layout/PageContainer.component";
import { BrowserRouter as Router} from "react-router-dom";
import { Routes } from "./Routes";

export const App = () => (
  <ChakraProvider>
    <Router>
      <VStack>
        <PageContainer>
          <Routes />
        </PageContainer>
      </VStack>
    </Router>
  </ChakraProvider>
);

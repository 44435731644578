import React from "react";
import { Container, Heading, SimpleGrid, Icon, Text, Stack, VStack, Box } from "@chakra-ui/react";
import { FaGlassCheers, FaTv, FaUtensils, FaWifi, FaBath, FaChair } from "react-icons/fa";

// Replace test data with your own
const features = [
  {
    title: "Kitchenette",
    text: "Each cottage has a kitchenette generously equipped with a refrigerator, microwave, and the majority of cookware you'll need",
    icon: FaUtensils,
  },
  {
    title: "HD Smart TV",
    text: 'Wall-mounted 50" flat screen TV for streaming a complimentary Netflix movie',
    icon: FaTv,
  },
  {
    title: "Private Bath",
    text: "Fresh towels, hand soap, bath soap, shampoo, and conditioner is supplied for your convenience",
    icon: FaBath,
  },
  {
    title: "Private Deck",
    text: "Each cottage has a 10’x10’ deck, which is supplied with chairs, a small side table, and a terrific view of Texas Hill Country sunsets",
    icon: FaChair,
  },
  {
    title: "WiFi",
    text: "High-speed fiber internet perfect for remote workers and online students",
    icon: FaWifi,
  },
  {
    title: '"Party Barn" Gazebo',
    text: "Community gathering spot featuring gas grills, a dining table, a gas fire pit, propane tanks, and plenty of seating for friends and family",
    icon: FaGlassCheers,
  },
];

export const Amenities = () => {
  return (
    <Box id="amenities" backgroundColor="#025362" color={"white"} w="full" py={"28"}>
      <Stack spacing={6} as={Container} maxW={"4xl"} textAlign={"center"}>
        <Heading fontSize={"3xl"} letterSpacing="widest" textTransform="uppercase" pb="16">
          Amenities
        </Heading>
        <Text fontSize={"xl"}>
          Nestled on more than 10 acres of the Texas Hill Country and with amazing countryside and sunset views, Windmill Meadows Guest Cottages is the perfect
          place to stage your next vacation! It's also a great place for groups of up to 12 to stay together.
        </Text>
      </Stack>
      <Container maxW={"6xl"} mt={20}>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
          {features.map((feature, idx) => (
            <VStack key={idx} align={"center"} justifyContent="center">
              <Box px={2}>
                <Icon as={feature.icon} boxSize="10" />
              </Box>
              <VStack align={"center"} textAlign="center">
                <Text fontWeight={600} letterSpacing="widest" textTransform="uppercase">
                  {feature.title}
                </Text>
                <Text maxW={{ base: "300px", md: "full" }} fontSize={"sm"}>{feature.text}</Text>
              </VStack>
            </VStack>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
};

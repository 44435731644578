import { Heading, SimpleGrid, VStack } from "@chakra-ui/react";
import React from "react";
import { Cottage } from "./Cottage.component";

export const Cottages = () => {
  return (
    <VStack id="cottages" w="full" py="20">
      <Heading pt={4} color={"#025362"} letterSpacing="widest" textTransform="uppercase" pb="16">
        Cottages
      </Heading>
      <SimpleGrid minChildWidth={{ base:"50%", lg:"400px"}} spacing='40px' w="80%">
        <Cottage img="assets/img/wm-hc.jpeg" link="https://www.cozivr.com/vrp/unit/holy-cow-cottage-w-amazing-hill-country-views" title="Holy Cow" side="approx. 512 sqft" description="1 king bed - sleeps 2" />
        <Cottage img="assets/img/wm-bb.jpeg" link="https://www.cozivr.com/vrp/unit/bonny-bluebonnets-cottage-w-stunning-hill-country-views" title="Bonny Bluebonnets" side="approx. 512 sqft" description="2 queen beds - sleeps 4" />
        <Cottage img="assets/img/wm-wc.jpg" link="https://www.cozivr.com/vrp/unit/wine-country-cottage-w-scenic-hill-country-views" title="Wine Country" side="approx. 512 sqft" description="1 king bed - sleeps 2" />
        <Cottage img="assets/img/wm-ls.jpeg" link="https://www.cozivr.com/vrp/unit/stargazing-retreat-at-lone-star-cottage" title="Lone Star" side="approx. 512 sqft" description="2 queen beds - sleeps 4" />
        <Cottage img="assets/img/wm-windmill.jpeg" link="https://www.cozivr.com/vrp/unit/the-windmill-breathtaking-hill-country-views" title="The Windmill" side="2 units" description='"Holy Cow" and "Bonny Bluebonnets" - sleeps 6'/>
        <Cottage img="assets/img/wm-meadow.jpeg" link="https://www.cozivr.com/vrp/unit/the-meadow-hill-country-sunset-views" title="The Meadow" side="2 units" description='"Wine Country" and "Lone Star" - sleeps 6'/>
        <Cottage img="assets/img/wm-firepit.jpeg" link="https://www.cozivr.com/vrp/unit/windmill-meadows-cottages-firepit-hill-country-views" title="Entire Property" side="4 units" description="All four cottages - sleeps 12"/>
      </SimpleGrid>
    </VStack>
  );
};

import * as React from "react";
import { VStack } from "@chakra-ui/react";
import Hero from "../components/layout/Hero.component";
import { Footer } from "../components/layout/Footer.component";
import { Amenities } from "../components/Amenities.component";
import { Cottages } from "../components/Cottages.component";
import { Carousel } from "../components/Carousel.component";

export const HomePage = () => {
  return (
    <VStack w={"full"} spacing="0">
      <Hero />
      <Amenities />
      <Cottages />
      <Carousel />
      <Footer />
    </VStack>
  );
};

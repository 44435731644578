import { Box, Center, Heading, Text, Stack, HStack, useColorModeValue, Button, Link } from "@chakra-ui/react";
import React from "react";

interface CottageProps {
  img: string;
  link: string;
  title: string;
  side?: string;
  description: string;
}

export const Cottage = ({ img, link, title, side = "", description }: CottageProps) => {
  return (
    <Center py={6}>
      <Box maxW={"445px"} w={"full"} bg={useColorModeValue("white", "gray.900")} boxShadow={"2xl"} rounded={"md"} p={6} overflow={"hidden"}>
        <Box h={"210px"} bg={"gray.100"} mt={-6} mx={-6} mb={6} pos={"relative"} backgroundImage={img} backgroundSize="cover" backgroundPosition="center" />
        <Stack h={"80px"}>
          <HStack justifyContent={"space-between"}>
            <Heading color={useColorModeValue("gray.700", "white")} fontSize={"xl"} fontFamily={"body"}>
              {title}
            </Heading>
            <Text color={"gray.500"}>{side ? `${side}` : description}</Text>
          </HStack>
          { side ? <Text color={"gray.500"}>{description}</Text> : <></> }
        </Stack>
        <Stack mt={6} direction={"row"} spacing={4} align={"center"} justifyContent="center">
          <Link href={link} target="_blank" textDecoration={"none"} _hover={{ textDecoration: "none" }}>
            <Button
              rounded={"full"}
              bg={"#025362"}
              color={"white"}
              _hover={{
                bg: "#013f4a",
              }}
            >
              Book Now
            </Button>
          </Link>
        </Stack>
      </Box>
    </Center>
  );
};
